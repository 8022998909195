import { useState } from "react";
import ContactUs from "../../Pages/Desktop/CMS/ContactUs/ContactUs";
import MContactUs from "../../Pages/Mobile/CMS/MContactUs/MContactUs";
import { HOME_SERVICES } from "../../Services/Home/HomeServices";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { toast } from "react-toastify";

function ContactUsConatiner() {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);

  const [complaintFields, setcomplaintFields] = useState<{
    fullname: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    interestedin: { value: string; warning: boolean };
  }>({
    fullname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    interestedin: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: {
        ...[attrName[0]],
        [attrName[1]]: value,
        warning:
          attrName[0] === "mobile"
            ? !validateMobileNumber(value)
            : attrName[0] === "email"
            ? !validateEmail(value)
            : attrName[0] === "fullname"
            ? !validateFullName(value)
            : isEmpty(value),
      },
    });
  };

  const interestedin_data = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    { label: "Car Insurance", value: "Car Insurance" },
    { label: "Two-wheeler Insurance", value: "Two-wheeler Insurance" },
    { label: "Term Insurance", value: "Term Insurance" },
    { label: "Others", value: "Others" },
  ];
  const CONTACT_US = () => {
    const toastID = toast.loading("Please wait...");
    let params = {
      FullName: complaintFields.fullname.value,
      Mobile: complaintFields.mobile.value,
      Email: complaintFields.email.value,
      InterestedIn: complaintFields.interestedin.value,
    };
    const onSuccess = (data: any) => {
      toast.dismiss(toastID);
      const results = data.results;
      const error = results.error;
      if (error) {
        toast.error("Something went wrong.");
      } else {
        setcomplaintFields({
          fullname: { value: "", warning: false },
          mobile: { value: "", warning: false },
          email: { value: "", warning: false },
          interestedin: { value: "", warning: false },
        });
        toast.success(
          "Thank you for your query! Our team will reach out to you shortly."
        );
      }
    };
    const onError = () => {};
    HOME_SERVICES.SUBMIT_CONTACT_US(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data = { ...complaintFields };
    data = {
      ...data,
      fullname: {
        ...data.fullname,
        warning: !validateFullName(data.fullname.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      interestedin: {
        ...data.interestedin,
        warning: isEmpty(data.interestedin.value),
      },
    };
    setcomplaintFields(data);
    if (
      data.fullname.warning === false ||
      data.email.warning === false ||
      data.mobile.warning === false ||
      data.interestedin.warning === false
    ) {
      CONTACT_US();
    }
  };

  return isMobile ? (
    <MContactUs
      complaintFields={complaintFields}
      setcomplaintFields={setcomplaintFields}
      validateForm={validateForm}
      loader={loader}
      fieldsUpdateState={fieldsUpdateState}
      interestedin_data={interestedin_data}
    />
  ) : (
    <ContactUs
      complaintFields={complaintFields}
      setcomplaintFields={setcomplaintFields}
      validateForm={validateForm}
      loader={loader}
      fieldsUpdateState={fieldsUpdateState}
      interestedin_data={interestedin_data}
    />
  );
}

export default ContactUsConatiner;
