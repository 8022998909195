import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import Buttons from "../../../../Component/Common/CustomButton/CustomButton";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import "../NeedHelp/Complaint.scss";
import HelmetComponent from "../../../../SupportingFiles/HelmetComponent";

function Careers({
  validate_Form,
  complaintFields,
  fieldsUpdateState,
  applyfor_data,
  setcomplaintFields,
  handleFileSelect,
  handleFileChange,
  selectedFileName,
  fileInputRef,
}: {
  validate_Form: Function;
  complaintFields: any;
  fieldsUpdateState: any;
  applyfor_data: any;
  setcomplaintFields: any;
  handleFileSelect: any;
  handleFileChange: any;
  selectedFileName: any;
  fileInputRef: any;
}) {
  return (
    <>
      <HelmetComponent
        title="Get Car, Bike, Health & Life Insurance with Bimastreet"
        description="Protect your future with top-rated bike, car, health, and term insurance. Discover affordable, comprehensive plans with free claim assistance."
        showCanonicalTag={true}
      />
      <Navbar />
      <div className="complaint-parent">
        <div className="frame-group">
          <div className="frame-wrapper">
            <div className="contact-us-parent">
              <div className="contact-us">
                <div className="contact-us1">
                  <h1>Careers</h1>
                </div>
              </div>
              <div className="we-eagerly-await">
                Seeking free spirits and visionaries who dare to think outside
                the box.
              </div>
            </div>
          </div>
          <div className="frame-container">
            <div className="objects-parent">
              <img
                className="objects-icon"
                alt=""
                src="./images/career_main_img.svg"
              />
              <div className="want-to-connect-with-us-parent">
                <div className="want-to-connect-container">
                  <span className="ant-to-connect">
                    Want to connect with us?
                  </span>
                </div>
                <div className="just-share-your">
                  Just share your information.
                </div>
              </div>
            </div>
            <div className="frame-div">
              <div className="input-field-parent">
                <div className="fileUpload">
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <Button
                    variant="contained"
                    onClick={handleFileSelect} // Call this function to trigger file input click
                  >
                    <CloudUploadIcon />
                    Browse to upload your CV
                  </Button>
                  {selectedFileName && <p> {selectedFileName}</p>}
                </div>

                <div className="input-field1">
                  <RKTextField
                    class_name="inputField"
                    title={"Full Name"}
                    validation_type="NAME"
                    value={complaintFields.fullname.value}
                    attrName={[
                      "fullname",
                      "value",
                      complaintFields,
                      setcomplaintFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={complaintFields.fullname.warning}
                    error_message={
                      isEmpty(complaintFields.fullname.value)
                        ? "Enter Full Name"
                        : "Enter Valid Full Name"
                    }
                  />
                  <div className="plan-type-container">
                    <div className="plan-type1">Full Name</div>
                  </div>
                </div>
                <div className="input-field-group">
                  <div className="input-field2">
                    <RKTextField
                      class_name="inputField"
                      title={"Mobile"}
                      value={complaintFields.mobile.value}
                      attrName={[
                        "mobile",
                        "value",
                        complaintFields,
                        setcomplaintFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={complaintFields.mobile.warning}
                      error_message={
                        isEmpty(complaintFields.mobile.value)
                          ? "Enter Mobile Number"
                          : "Enter Valid mobile number"
                      }
                      max_length={10}
                      validation_type="NUMBER"
                    />
                  </div>
                  <div className="input-field2">
                    <RKTextField
                      class_name="inputField"
                      title={"Email"}
                      value={complaintFields.email.value}
                      attrName={[
                        "email",
                        "value",
                        complaintFields,
                        setcomplaintFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={complaintFields.email.warning}
                      error_message={
                        isEmpty(complaintFields.email.value)
                          ? "Enter Email"
                          : "Enter Valid Email"
                      }
                    />
                  </div>
                </div>
                <div className="input-field-wrapper">
                  <div className="input-field4">
                    <SelectDropdown
                      class_name="inputField"
                      title="Want to apply for"
                      value={complaintFields.applyfor.value}
                      attrName={[
                        "applyfor",
                        "value",
                        complaintFields,
                        setcomplaintFields,
                      ]}
                      value_update={fieldsUpdateState}
                      data={applyfor_data}
                      warn_status={complaintFields.applyfor.warning}
                    />
                  </div>
                </div>
                <div className="ctaBtn">
                  <Buttons
                    onClickFunction={validate_Form}
                    class_name="greenLgBtn"
                    text_name="Submit Details"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Careers;
