import { useRef, useState } from "react";
import Careers from "../../Pages/Desktop/CMS/Careers/Careers";
import MCareers from "../../Pages/Mobile/CMS/MCareers/MCareers";
import { HOME_SERVICES } from "../../Services/Home/HomeServices";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { toast } from "react-toastify";

function CarrierConatiner() {
  const isMobile = useIsMobile();
  const [complaintFields, setcomplaintFields] = useState<{
    fullname: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    applyfor: { value: string; warning: boolean };
  }>({
    fullname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    applyfor: { value: "", warning: false },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [base64String, setBase64String] = useState<string>("");

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files && event.target.files[0];
    console.log("selectedFile", selectedFile);

    if (selectedFile) {
      // Check if the selected file type is PDF or DOCX
      if (
        selectedFile.type === "application/pdf" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        // Check file size (in bytes)
        const fileSizeLimit = 5 * 1024 * 1024 * 1024; // 5 GB in bytes
        if (selectedFile.size <= fileSizeLimit) {
          const reader = new FileReader();

          reader.onload = (e) => {
            let base64String = e.target?.result as string;
            base64String = base64String.replace(/^data:[^;]+;base64,/, "");
            console.log("base64String", base64String);
            setBase64String(base64String); // Store base64String in state
            setSelectedFileName(selectedFile.name); // Update selected file name
          };
          reader.readAsDataURL(selectedFile);
        } else {
          toast.error("File size exceeds the limit of 5 GB.");
          // Clear the input field
          event.target.value = "";
        }
      } else {
        toast.error("Please upload a PDF or DOCX file.");
        // Clear the input field
        event.target.value = "";
      }
    }
  };

  const validate_Form = () => {
    let hasError = false; // Initialize hasError to false
    let data = { ...complaintFields };
    console.log("complaintFields", data.fullname.warning);
    data = {
      ...data,
      fullname: {
        ...data.fullname,
        warning: !validateFullName(data.fullname.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      applyfor: {
        ...data.applyfor,
        warning: isEmpty(data.applyfor.value),
      },
    };
    setcomplaintFields(data);

    // Check if any field has a warning (i.e., validation error)
    if (
      data.fullname.warning ||
      data.email.warning ||
      data.mobile.warning ||
      data.applyfor.warning
    ) {
      hasError = true; // Set hasError to true if any field has a warning
    }

    if (!hasError && base64String) {
      Career_Data(); // Call Career_Data if validation passes
    }
    if (selectedFileName === "") {
      toast.error("Select a file ");
    }
  };

  const Career_Data = () => {
    const toastID = toast.loading("Please wait...");
    let params = {
      name: complaintFields.fullname.value,
      email: complaintFields.email.value,
      mobile: complaintFields.mobile.value,
      resume: base64String,
      applied_for: complaintFields.applyfor.value,
    };
    const onSuccess = (data: any) => {
      const results = data?.results;
      const error = results?.error;
      const message = results?.message;
      toast.dismiss(toastID);
      if (error) {
        toast.error(`${message}`);
      } else {
        setcomplaintFields({
          fullname: { value: "", warning: false },
          mobile: { value: "", warning: false },
          email: { value: "", warning: false },
          applyfor: { value: "", warning: false },
        });
        toast.success(
          "Thank you for your query! Our team will reach out to you shortly."
        );
      }
    };
    const onError = (data: any) => {};
    HOME_SERVICES.SUBMIT_CAREER(onSuccess, onError, params);
  };

  const applyfor_data = [
    {
      label: "Business Development Officer",
      value: "Business Development Officer",
    },
    { label: "Sales Officer", value: "Sales Officer" },
  ];

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: {
        ...[attrName[0]],
        [attrName[1]]: value,
        warning:
          attrName[0] === "mobile"
            ? !validateMobileNumber(value)
            : attrName[0] === "email"
            ? !validateEmail(value)
            : attrName[0] === "fullname"
            ? !validateFullName(value)
            : isEmpty(value),
      },
    });
  };

  return isMobile ? (
    <MCareers
      validate_Form={validate_Form}
      complaintFields={complaintFields}
      fieldsUpdateState={fieldsUpdateState}
      applyfor_data={applyfor_data}
      setcomplaintFields={setcomplaintFields}
      handleFileSelect={handleFileSelect}
      handleFileChange={handleFileChange}
      selectedFileName={selectedFileName}
      fileInputRef={fileInputRef}
    />
  ) : (
    <Careers
      validate_Form={validate_Form}
      complaintFields={complaintFields}
      fieldsUpdateState={fieldsUpdateState}
      applyfor_data={applyfor_data}
      setcomplaintFields={setcomplaintFields}
      handleFileSelect={handleFileSelect}
      handleFileChange={handleFileChange}
      selectedFileName={selectedFileName}
      fileInputRef={fileInputRef}
    />
  );
}

export default CarrierConatiner;
