import { Box, Link, Slider } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../../CommonSCSS/ProductPages.scss";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/Common/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import FAQ from "../../../../Component/Desktop/ProductPages/FAQ/FAQ";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import { AddTermDTO } from "../../../../DTO/TermDTO/AddTermFormDTO";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { TERM_ROUTES } from "../../../../Router/Path/TermRoutes";
import { TERM_SERVICES } from "../../../../Services/Term/TermServices";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { TermSlice } from "../../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  formatToCurrency,
  hasWarning,
  isEmpty,
  validateMobileNumber,
  validatePincode,
  validateRegno,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TTermAddForm } from "../../../../Type/Term/TTermAddForm";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const TermInsurance = () => {
  const TERM_INSURANCE = useAppSelector(
    (state) => state.PRODUCT_PAGES.TERM_INSURANCE.attributes.data
  );
  const DATA = useAppSelector((state) => state.CommonDropdownData);
  const { ADD_FORM, DROPDOWN_DATA } = useAppSelector((state) => state.Term);
  const [formData, setFormData] = useState<TTermAddForm>(ADD_FORM);
  const [pageStatus, setPageStatus] = useState<"1" | "2">("1");
  const [loader, setLoader] = useState<boolean>(false);
  const location = useLocation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const validateForm = () => {
    let data: TTermAddForm = { ...formData };

    if (pageStatus === "1") {
      data = {
        ...data,
        gender: { ...data.gender, warning: isEmpty(formData.gender.value) },
        age: { ...data.age, warning: isEmpty(formData.age.value) },
        pincode: {
          ...data.pincode,
          warning: !validatePincode(formData.pincode.value),
        },
        mobile: {
          ...data.mobile,
          warning: false,
        },
        name: { ...data.name, warning: false },
      };
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
        name: { ...data.name, warning: isEmpty(formData.name.value) },
      };
    }

    if (!hasWarning(data)) {
      if (pageStatus === "1") {
        setPageStatus("2");
      } else {
        dispatch(TermSlice.actions.setTermAddFormData(formData));
        add_form(data);
      }
    }
    setFormData({ ...data });
  };
  const backFunction = () => {
    if (pageStatus === "2") {
      setPageStatus("1");
    } else {
      navigate(COMMON_ROUTES.HOME);
    }
  };

  const add_form = (data: TTermAddForm) => {
    const onSuccess = (res: any) => {
      const results = res;
      const response = results?.response;
      const error = results?.error;
      if (!error) {
        const quote_no = response?.quote_no;
        window.location.href = `${FRONTEND_DOMAIN}${TERM_ROUTES.QUOTE}?type=TM&quote_no=${quote_no}`;
      } else {
        toast.error(`${results?.message}`);
      }

      setLoader(false);
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };

    let params: AddTermDTO = {
      lifeInsurance_module: "TermPlan",
      lifeInsurance_type: "Term Plan",
      termSmoker: data.smoke,
      termAnnualIncome: `${data.annualIncome}00000`,
      termAnnualIncomeOther: `${data.annualIncome}00000`,
      termGender: data.gender.value,
      termDob: FORMAT_DATE_DD_MM_YYYY(data.age.value),
      termName: data.name.value,
      termMobile: data.mobile.value,
      termPincode: data.pincode.value,
    };

    dispatch(
      UserProfileSlice.actions.setAccountUserDataMultipleKey([
        { key: "name", value: data.name.value },
        { key: "pincode", value: data.pincode.value },
        { key: "gender", value: data.gender.value },
        { key: "age", value: moment(data.age.value).format("dd-MM-YYYY") },
        { key: "mobile", value: data.mobile.value },
      ])
    );
    setLoader(true);
    TERM_SERVICES.addTermFormData(onSuccess, onError, params);
  };

  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "reg_no") {
      setFormData((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateRegno(value) },
      }));
    }
    if (attrName === "annualIncome" || attrName === "smoke") {
      setFormData((prev: any) => ({ ...prev, [attrName]: value }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  const isMobile = useIsMobile();
  // Scroll to top
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Attach scroll event listener to monitor scroll position
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <HelmetComponent
        title="Term Life Insurance Online | Best Term Insurance Plan | Best Term Insurance Plan Companies | Buy Term Life Insurance Online | The Best Life Insurance | Top Rated Term Life Insurance Companies	"
        description="Explore our Comprehensive Term Life Insurance options and secure your future with the Best Term Insurance policies. Buy with confidence, knowing you've chosen a reliable Term Life Insurance Policy that fits your needs."
        showCanonicalTag={true}
      /> */}

      <Helmet>
        <title>
          Best Term Life Insurance Plans Online | Top-Rated Companies &
          Affordable Coverage Options
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Explore our Comprehensive Term Life Insurance options and secure your future with the Best Term Insurance policies. Buy with confidence, knowing you've chosen a reliable Term Life Insurance Policy that fits your needs."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Term Life Insurance Online, Best Term Insurance Plan, Best Term Insurance Plan Companies, Buy Term Life Insurance Online, The Best Life Insurance, Top Rated Term Life Insurance Companies	"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bimastreet" />
        <meta
          property="og:title"
          content="Best Term Life Insurance Plans Online | Top-Rated Companies &
          Affordable Coverage Options"
        />
        <meta
          property="og:description"
          content="Explore our Comprehensive Term Life Insurance options and secure your future with the Best Term Insurance policies. Buy with confidence, knowing you've chosen a reliable Term Life Insurance Policy that fits your needs."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FRONTEND_DOMAIN}${location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://www.bimastreet.com/images/term_product_icon.svg"
        />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
        <link rel="canonical" href={`${FRONTEND_DOMAIN}${location.pathname}`} />
      </Helmet>

      {isMobile ? <MNavbar /> : <Navbar />}
      <Box className="productPagesWrapper">
        {/* Title */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12} textAlign="center">
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img src="./images/term_product_icon.svg" height="67px" />
              {TERM_INSURANCE?.title}
            </h1>
            <p style={{ opacity: "0.5" }}>{TERM_INSURANCE?.desc}</p>
          </Grid>
        </Grid>

        {/* Header / From */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing columnReverse">
          <Grid lg={6} xs={12}>
            <h2
              dangerouslySetInnerHTML={{
                __html: TERM_INSURANCE?.primary_info?.title,
              }}></h2>
            <p style={{ marginBottom: "24px", marginTop: "24px" }}>
              {TERM_INSURANCE?.primary_info?.desc}
            </p>
          </Grid>
          <Grid lg={6} xs={12}>
            <div className="formSection termbg">
              {pageStatus === "1" ? (
                <>
                  <Grid
                    container
                    spacing={3}
                    className="row "
                    sx={{ justifyContent: "center" }}>
                    <Grid
                      xs={12}
                      sx={{ textAlign: "center", paddingTop: "0px" }}>
                      <h1>
                        Secure your family's future with{" "}
                        <span className="greenText">Best</span>
                        <span>Term Insurance</span>
                      </h1>
                    </Grid>
                    <Grid xs={12} sm>
                      <p className="toinsure">
                        Annual Income:{" "}
                        <span>
                          {formData.annualIncome.toString().length > 3
                            ? formatToCurrency(formData.annualIncome)
                            : formatToCurrency(formData.annualIncome * 100000)}
                        </span>
                      </p>
                      <Slider
                        className="rangeSlider ml-3"
                        sx={{ marginTop: "20px" }}
                        value={
                          formData.annualIncome.toString().length > 3
                            ? formData.annualIncome / 100000
                            : formData.annualIncome
                        }
                        min={2}
                        step={1}
                        max={100}
                        scale={(value) => {
                          return value + 1;
                        }}
                        getAriaValueText={(value) => formatToCurrency(value)}
                        onChange={(e, v) =>
                          updateMasterState("annualIncome", `${v}`)
                        }
                        aria-labelledby="non-linear-slider"
                      />
                    </Grid>
                    <Grid
                      xs
                      sx={{
                        maxWidth: "152px",
                      }}>
                      <p className="toinsure"> Do You Smoke?</p>
                      <ul
                        className="varient_list left_align mb-0"
                        style={{ marginTop: "8px" }}>
                        <li
                          onClick={() => {
                            updateMasterState("smoke", "Y");
                          }}
                          className={formData.smoke === "Y" ? "active" : ""}>
                          Yes
                        </li>
                        <li
                          onClick={() => {
                            updateMasterState("smoke", "N");
                          }}
                          className={formData.smoke === "N" ? "active" : ""}>
                          No
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    columnSpacing={{ xs: 2, md: 3 }}
                    rowSpacing={{ xs: 3, md: 0 }}
                    sx={{ justifyContent: "center", marginTop: "20px" }}>
                    <Grid xs={6} sm={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Gender"
                        value={formData.gender.value}
                        attrName={"gender"}
                        value_update={updateMasterState}
                        data={DATA.GENDER}
                        warn_status={formData.gender.warning}
                        error_message={"Select Gender"}
                      />
                    </Grid>
                    <Grid xs={6} sm={4}>
                      <DatePicker
                        class_name="inputField"
                        title={"Date of Birth"}
                        value={formData.age.value}
                        attrName={"age"}
                        value_update={updateMasterState}
                        warn_status={formData.age.warning}
                        error_message="Select DOB"
                        min_date={65}
                        max_date={18}
                      />
                    </Grid>
                    <Grid xs={6} sm={4}>
                      <RKTextField
                        validation_type="NUMBER"
                        max_length={6}
                        class_name="inputField"
                        title={"Pincode"}
                        value={formData.pincode.value}
                        attrName={"pincode"}
                        value_update={updateMasterState}
                        warn_status={formData.pincode.warning}
                        error_message={
                          isEmpty(formData.pincode.value)
                            ? "Enter pincode"
                            : "Enter valid pincode"
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  spacing={3}
                  className="row"
                  sx={{ justifyContent: "center" }}>
                  <Grid
                    xs={12}
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    paddingTop="0px">
                    <Link className="backStep" onClick={() => backFunction()} />
                    <h3
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginLeft: "-32px",
                      }}>
                      Personal <span>Details</span>
                    </h3>
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      class_name="inputField"
                      title={"Full Name"}
                      value={formData.name.value}
                      attrName={"name"}
                      value_update={updateMasterState}
                      warn_status={formData.name.warning}
                      error_message="Enter Full Name"
                      validation_type="NAME"
                    />
                  </Grid>
                  <Grid xs={6}>
                    <RKTextField
                      validation_type="NUMBER"
                      max_length={10}
                      class_name="inputField"
                      title={"Mobile"}
                      value={formData.mobile.value}
                      attrName={"mobile"}
                      value_update={updateMasterState}
                      warn_status={formData.mobile.warning}
                      error_message={
                        isEmpty(formData.mobile.value)
                          ? "Enter Mobile Number"
                          : "Enter valid mobile number"
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid xs={12} className="ctaBtn mt-5" textAlign={"center"}>
                  <CustomButton
                    class_name="greenXlBtn"
                    text_name={pageStatus === "1" ? "Continue" : "View Quotes"}
                    onClickFunction={() => validateForm()}
                    // loading={showLoader}
                  />
                </Grid>
                {pageStatus === "2" ? (
                  <Grid
                    xs={12}
                    className="disabledCheckbox"
                    textAlign={"center"}>
                    <CustomCheckbox
                      label={
                        <>
                          By clicking on view quotes you agree to all{" "}
                          <a
                            onClick={() =>
                              window.open(
                                COMMON_ROUTES.TERM_AND_CONDTION,
                                "_blank"
                              )
                            }>
                            Terms & Conditions
                          </a>
                          .
                        </>
                      }
                      defaultChecked
                      value={true}
                      disabled={true}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px">
              <Grid container columnSpacing={3} rowSpacing={0}>
                <Grid lg={12} md={12}>
                  <h2
                    style={{ marginBottom: "24px" }}
                    dangerouslySetInnerHTML={{
                      __html: TERM_INSURANCE?.gradient_box_title,
                    }}></h2>
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} rowSpacing={0}>
                {TERM_INSURANCE?.gradient_data?.map((data) => (
                  <Grid md={6}>
                    <Box className="boderWhitegradientBox">
                      <h5 className="tickTitle">{data.title}</h5>
                      <p>{data.desc}</p>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="recommendation sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px">
              <Box className="inner">
                <img src="./images/shield-icon.svg" width="58px" />
                <div style={{ flex: "1 0 0" }}>
                  <h4>{TERM_INSURANCE?.buy_quote?.title}</h4>
                  <p>{TERM_INSURANCE?.buy_quote?.desc}</p>
                </div>
                <div className="ctaBtn">
                  <Buttons
                    onClickFunction={scrollToTop}
                    class_name="greenMdBtn"
                    text_name="Buy Now"
                  />
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12}>
            <h2
              dangerouslySetInnerHTML={{
                __html: TERM_INSURANCE?.list_cont_heading?.title,
              }}></h2>
            <p style={{ marginBottom: "24px", marginTop: "24px" }}>
              {TERM_INSURANCE?.list_cont_heading?.desc}
            </p>
            <h5 className="mb-6">Types of Term Life Insurance in India</h5>
            <ul className="numberList" style={{ flexDirection: "row" }}>
              {TERM_INSURANCE?.list_cont_data?.map((data, index) => (
                <li style={{ flex: "inherit" }}>
                  <span>{index + 1}</span>
                  <div>
                    <h4>{data.title}</h4>
                    <p>{data.desc}</p>
                  </div>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              display="flex"
              padding="20px 30px"
              justifyContent="center"
              alignItems="center"
              gap="24px"
              alignSelf="stretch">
              <img src="./images/policy-ponts.svg" width="141px" />
              <p>{TERM_INSURANCE?.product_info}</p>
            </Box>
          </Grid>
        </Grid>

        {/* FAQ */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12} textAlign="center">
            <h2 style={{ marginBottom: "18px" }}>
              {TERM_INSURANCE?.faq_heading?.title}
            </h2>
            <p style={{ marginBottom: "18px" }}>
              {TERM_INSURANCE?.faq_heading?.desc}
            </p>
          </Grid>
          <Grid xs={12}>
            <FAQ data={TERM_INSURANCE?.faq_data} />
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing">
          <Grid xs={12} textAlign="center">
            <h4 style={{ marginBottom: "18px" }}>
              {TERM_INSURANCE?.disclaimer}
            </h4>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="recommendation"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px">
              <Box className="inner">
                <img src="./images/idea.svg" width="94px" />
                <div style={{ flex: "1 0 0" }}>
                  <p>{TERM_INSURANCE?.faq_note}</p>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default TermInsurance;
