import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import CommonRoutes from "./Router/Routes/CommonRoutes/CommonRoutes";
import { EHomeSectionName } from "./Services/Enum/EHome";
import { HOME_SERVICES } from "./Services/Home/HomeServices";
import { CMSSlice } from "./Store/Slice_Reducer/CMS/CMSSlice";
import { HomeSlice } from "./Store/Slice_Reducer/Home/HomeSlice";
import { ProductPagesSlice } from "./Store/Slice_Reducer/ProductPages/ProductPagesSlice";
import { useAppDispatch, useAppSelector } from "./Store/hooks";
import { THomePage } from "./Type/Home/THomePage";
import { TCMSSlice } from "./Type/TCMSSlice";
import { TProductPage, TProductPageSlice } from "./Type/TProductPageSlice";
import GoogleTagManager from "./SupportingFiles/GoogleTagManger";

function App() {
  const dispatch = useAppDispatch();
  const { PAGE_STATUS } = useAppSelector((state) => state.Home);

  useEffect(() => {
    load_home_page_data();
    CMS_DATA();
    PRODUCT_PAGE_DATA();
  }, []);

  const load_home_page_data = () => {
    /**
     * Handle the successful response from the API and update the state with the data.
     * @param {any} res - The response object from the API.
     */
    const onSuccess = (res: any) => {
      // Extract the data from the response

      const response = res.data.data.attributes.data;

      const findSectionIndexByName = (sectionName: EHomeSectionName): number =>
        response.findIndex((value: any) => value.section_name === sectionName);

      const getSectionByName = (sectionName: EHomeSectionName): any => {
        const sectionIndex = findSectionIndexByName(sectionName);
        if (sectionIndex !== -1) {
          return response[sectionIndex];
        }
        return {}; // Section not found
      };

      const manipulated_data: THomePage = {
        header: { ...getSectionByName(EHomeSectionName.HEADER) },
        awards: {
          ...getSectionByName(EHomeSectionName.AWARDS),
          section_index: findSectionIndexByName(EHomeSectionName.AWARDS),
        },
        footer: {
          ...getSectionByName(EHomeSectionName.FOOTER),
          section_index: 10,
        },
        how_it_works: {
          ...getSectionByName(EHomeSectionName.HOW_IT_WORKS),
          section_index: findSectionIndexByName(EHomeSectionName.HOW_IT_WORKS),
        },
        our_clients: {
          ...getSectionByName(EHomeSectionName.OUR_CLIENTS),
          section_index: findSectionIndexByName(EHomeSectionName.OUR_CLIENTS),
        },
        our_partners: {
          ...getSectionByName(EHomeSectionName.OUR_PARTNERS),
          section_index: findSectionIndexByName(EHomeSectionName.OUR_PARTNERS),
        },
        our_products: {
          ...getSectionByName(EHomeSectionName.OUR_PRODUCTS),
          section_index: findSectionIndexByName(EHomeSectionName.OUR_PRODUCTS),
        },
        testimonials: {
          ...getSectionByName(EHomeSectionName.TESTIMONIALS),
          section_index: findSectionIndexByName(EHomeSectionName.TESTIMONIALS),
        },
        top_rated: {
          ...getSectionByName(EHomeSectionName.TOP_RATED),
          section_index: findSectionIndexByName(EHomeSectionName.TOP_RATED),
        },
        PAGE_STATUS: PAGE_STATUS,
      };

      // Update the state with the manipulated data
      dispatch(HomeSlice.actions.setHomePageData(manipulated_data));
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    HOME_SERVICES.getHomePageData(onSuccess, onError);
  };

  const CMS_DATA = () => {
    /**
     * Handle the successful response from the API and update the state with the data.
     * @param {any} res - The response object from the API.
     */
    const onSuccess = (res: any) => {
      // Extract the data from the response

      const response = res.data.data.attributes;

      const data: TCMSSlice = {
        disclaimer: response.Disclaimer,
        privacy_policy: response.PrivacyPolicy,
        refund_cancellation_policy: response?.RefundPolicy,
        terms_condition: response?.TermCond,
        key_management_personal: response.KeyMP,
        md_ceo: response.MDCEO,
        about_us: response.about_us,
      };

      // Update the state with the manipulated data
      dispatch(CMSSlice.actions.setCMSData(data));
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    HOME_SERVICES.getCMSPageData(onSuccess, onError);
  };

  const PRODUCT_PAGE_DATA = () => {
    const onSuccess = (res: any) => {
      const { data } = res.data.data.attributes.data;

      const findSectionIndexByName = (sectionName: string): number =>
        data.findIndex(
          (value: TProductPage) =>
            value.attributes.data.section_type === sectionName
        );

      const bike_insurance: TProductPage = {
        ...data[findSectionIndexByName("Bike Insurance")],
      };
      const car_insurance: TProductPage = {
        ...data[findSectionIndexByName("Car Insurance")],
      };
      const health_insurance: TProductPage = {
        ...data[findSectionIndexByName("Health Insurance")],
      };
      const term_insurance: TProductPage = {
        ...data[findSectionIndexByName("Term Insurance")],
      };
      const response: TProductPageSlice = {
        BIKE_INSURANCE: {
          attributes: {
            data: {
              ...bike_insurance.attributes.data,
              faq_data: bike_insurance.attributes.data.faq_data.map(
                (faqItem) => ({
                  expanded: `${0}`,
                  title: faqItem.title,
                  desc: faqItem.desc,
                })
              ),
            },
          },
        },

        CAR_INSURANCE: {
          attributes: {
            data: {
              ...car_insurance.attributes.data,
              faq_data: car_insurance.attributes.data.faq_data.map(
                (faqItem) => ({
                  expanded: `${0}`,
                  title: faqItem.title,
                  desc: faqItem.desc,
                })
              ),
            },
          },
        },

        HEALTH_INSURANCE: {
          attributes: {
            data: {
              ...health_insurance.attributes.data,
              faq_data: health_insurance.attributes.data.faq_data.map(
                (faqItem) => ({
                  expanded: `${0}`,
                  title: faqItem.title,
                  desc: faqItem.desc,
                })
              ),
            },
          },
        },

        TERM_INSURANCE: {
          attributes: {
            data: {
              ...term_insurance.attributes.data,
              faq_data: term_insurance.attributes.data.faq_data.map(
                (faqItem) => ({
                  expanded: `${0}`,
                  title: faqItem.title,
                  desc: faqItem.desc,
                })
              ),
            },
          },
        },
      };

      // Dispatch the action to update the state with the manipulated data
      dispatch(ProductPagesSlice.actions.setProductPagesData(response));
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    HOME_SERVICES.GET_PRODUCT_PAGE_DATA(onSuccess, onError);
  };

  return (
    <>
      <GoogleTagManager />
      <BrowserRouter>
        <CommonRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
