import { useEffect } from "react";

const GoogleTagManager = () => {
  useEffect(() => {
    // Load the gtag.js script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-19DCW2HHWL";
    document.head.appendChild(script);

    // Initialize gtag
    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-19DCW2HHWL');
    `;
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script2);
    };
  }, []);

  return null; // No UI element, just injecting the tag
};

export default GoogleTagManager;
