import { CommonURLs } from "../../URLCollection/Common/CommonURLs";
import { GetAPI } from "../../Axios/AxiosInstance";
import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { FormDataPostAPI } from "../../Axios/FormDataAxiosInstanceToken";

const getHomePageData = (onSuccess: Function, onError: Function) => {
  GetAPI.call(
    CommonURLs.LOAD_HOMEPAGE_DATA,
    (res: any) => onSuccess(res),
    onError
  );
};

const getCMSPageData = (onSuccess: Function, onError: Function) => {
  GetAPI.call(CommonURLs.CMS_DATA, (res: any) => onSuccess(res), onError);
};

const GET_PRODUCT_PAGE_DATA = (onSuccess: Function, onError: Function) => {
  GetAPI.call(
    CommonURLs.PRODUCT_PAGE_DATA,
    (res: any) => onSuccess(res),
    onError
  );
};

const Submit_claim = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  FormDataPostAPI.call(
    CommonURLs.AMAZE_ZOHO_API,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const SUBMIT_CONTACT_US = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    CommonURLs.CONTACT_US,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const SUBMIT_CAREER = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto:any
) => {
  PostAPI.call(
    CommonURLs.Career,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const SUBMIT_NEED_HELP = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    CommonURLs.NEED_HELP,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
export const HOME_SERVICES = {
  getHomePageData,
  getCMSPageData,
  GET_PRODUCT_PAGE_DATA,
  Submit_claim,
  SUBMIT_CONTACT_US,
  SUBMIT_CAREER,
  SUBMIT_NEED_HELP,
};
