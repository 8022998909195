import { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import HealthForm from "../../../Pages/Desktop/Health/FormPage/HealthForm";
import MHealthForm from "../../../Pages/Mobile/Health/FormPage/MHealthForm";
import { COMMON_ROUTES } from "../../../Router/Path/CommonRoutes";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_FORM_SERVICES } from "../../../Services/HealthServices/HealthFormServices";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { child_gender } from "../../../SupportingFiles/HealthHelper";
import {
  calculateAge,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { THealthAddForm } from "../../../Type/Health/THealthSlice";
import { CLIENTS } from "../../../URLCollection/Clients";
import { FRONTEND_DOMAIN } from "../../../URLCollection/Domain";
import Travelform from "../../../Pages/Desktop/Travel/Form/Travelform";
import MTravelForm from "../../../Pages/Mobile/Travel/Form/MTravelForm";

function TravelFormContainer() {
  const { Health } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const [pageStatus, setPageStatus] = useState<"1" | "2">("1");
  const { ADD_FORM } = useAppSelector((state) => state.Health);
  const [formData, setFormData] = useState<THealthAddForm>(ADD_FORM);
  const [showLoader, setShowLoader] = useState(false);

  const [memberStatus, setMemberstatus] = useState({
    oneAdult: true,
    twoAdults: false,
    son: false,
    daughter: false,
  });
  const relationship_data = [
    { label: "Spouse", value: "Spouse" },
    { label: "Mother", value: "Mother" },
    { label: "Father", value: "Father" },
  ];

  const backFunction = () => {
    if (pageStatus === "2") {
      setPageStatus("1");
    } else {
      navigate(COMMON_ROUTES.HOME);
    }
  };

  return isMobile ? (
    <MTravelForm
      formData={formData}
      backFunction={backFunction}
      loading={showLoader}
      relationship_data={relationship_data}
    />
  ) : (
    <Travelform
      formData={formData}
      backFunction={backFunction}
      loading={showLoader}
      relationship_data={relationship_data}
    />
  );
}

export default TravelFormContainer;
