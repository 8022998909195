import { NODE_DOMAIN, PHP_DOMAIN, STRAPI_DOMAIN } from "../Domain";

export const CommonURLs = {
  GENERATE_TOKEN: `${PHP_DOMAIN}/api/users/generate-token.json`,

  //strAPI
  LOAD_HOMEPAGE_DATA: `${STRAPI_DOMAIN}/api/home-page?populate=deep`,
  CMS_DATA: `${STRAPI_DOMAIN}/api/cms-page?populate=deep`,
  PRODUCT_PAGE_DATA: `${STRAPI_DOMAIN}/api/prod-page?populate=deep`,
  SUBMIT_CLAIM: `${PHP_DOMAIN}/api/users/send-claim-page-email.json`,
  AMAZE_ZOHO_API:`${NODE_DOMAIN}/api/v1/email/zohoapi`,

  //Premium Confiramtion
  PREMIUM_CONFIRMATION: `${PHP_DOMAIN}/api/policies/payment-confirmation.json`,
  //cms url
  CONTACT_US: `${PHP_DOMAIN}/api/cms/contactUs.json`,
  Career: `${PHP_DOMAIN}/api/cms/career.json`,
  NEED_HELP: `${PHP_DOMAIN}/api/cms/needHelp.json`,
};
